import { useState, useRef, useEffect } from "react";
import { message, Progress, Button } from "antd";
import SparkMD5 from "spark-md5";
import axios from "axios";
import "./uploadingPanel.css";
import copy from "copy-to-clipboard";
import { FileZipOutlined } from "@ant-design/icons";

const SLICE_SIZE = 1024 * 1024 * 20;

const UploadingPanel = ({ uuid, file, project, baseUrl }) => {
  const [uploadProgress, setUploadProgress] = useState(0); // 上传进度条
  const [finishStatus, setFinishStatus] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [uploadStatus, setUploadStatus] = useState(true);
  const chunkList = useRef([]);
  const fileRef = useRef(null);
  const hash = useRef(null); // 文件hash值
  const partList = useRef([]); // 分片后的文件

  useEffect(() => {
    if (file) {
      changeFile(file);
    }
  }, [file]);

  // 获取文件,并分解
  const changeFile = (file) => {
    setFinishStatus(false);
    setUploadStatus(true); //文件改变。等于重置
    fileRef.current = file;
    if (file) {
      setUploadProgress(0);
      createChunkFile(file);
    }
  };

  //分解文件
  async function createChunkFile(file) {
    const list = [];
    const count = Math.ceil(file.size / SLICE_SIZE);
    const partSize = file.size / count;
    const sectionFile = file.slice(0, partSize);
    const buffer = await fileParse(sectionFile);
    const spark = new SparkMD5.ArrayBuffer();
    spark.append(buffer);
    hash.current = spark.end();
    for (let i = 0; i < count; i++) {
      // 计算分片起始位置
      let start = i * SLICE_SIZE;
      // 计算分片结束位置
      let end = start + SLICE_SIZE;
      // 最后一片特殊处理
      if (end > file.size) {
        end = file.size;
      }
      const chunk = file.slice(start, end);
      let item = {
        chunk: chunk,
        name: `${i}_${hash.current}`,
        chunkSize: chunk.size,
        count: count,
        MD5: hash.current,
        index: i,
      };
      list.push(item);
    }
    partList.current = list;
    uploadFile();
  }

  //转换文件类型（解析为BUFFER数据）
  function fileParse(file) {
    return new Promise((resolve) => {
      const fileRead = new FileReader();
      fileRead.readAsArrayBuffer(file);
      fileRead.onload = (ev) => {
        resolve(ev.target.result);
      };
    });
  }

  // 上传文件
  async function uploadFile() {
    if (fileRef.current) {
      getLoadingFiles();
    }
  }

  //上传失败,点击重新上传
  const reUpload = () => {
    setUploadStatus(true);
    uploadFile(file);
  };

  //获取已上传的情况。改变进度
  async function getLoadingFiles() {
    const file = fileRef.current;
    axios
      .get(
        `${baseUrl}/upload/count?hash=${hash.current}&uploadFile=${file.name}&size=${file.size}&uuid=${uuid}&project=${project}`
      )
      .then((res) => {
        if (res.data.code === "1") {
          const count = res.data.chunkList ? res.data.chunkList.length : 0;
          chunkList.current = res.data.chunkList;
          setUploadProgress(
            ((count * 100) / partList.current.length).toFixed(2)
          );
          //后端已经完成传送情况
          if (res.data.download_url) {
            setDownloadUrl(res.data.download_url);
            setUploadProgress(100);
            setFinishStatus(false);
            return;
          }
          //兼容网络中断
          res.data.chunkList.length > 0
            ? uploadFn(res.data.chunkList.length - 1)
            : uploadFn();
        }
        if (res.data.code === "-1") {
          message.warning("本包已有一个进行中的上传任务");
          return;
        }
      })
      .catch(() => {
        message.error("上传失败");
        setUploadStatus(false);
      });
  }

  //上传文件
  async function uploadFn(startIndex = 0) {
    if (partList.current.length === 0) return;
    const requestList = [];
    partList.current.forEach((item, index) => {
      const fn = () => {
        let formData = new FormData();
        formData.append("chunk", item.chunk);
        formData.append("name", item.name);
        formData.append("chunkSize", item.chunkSize);
        formData.append("count", item.count);
        formData.append("MD5", item.MD5);
        formData.append("index", item.index);
        formData.append("uploadFile", fileRef.current.name);
        return axios
          .post(`${baseUrl}/upload?uuid=${uuid}&project=${project}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            const data = res.data;
            if (data.code === "1") {
              setUploadProgress(
                (((data.index + 1) * 100) / partList.current.length).toFixed(2)
              );
            }
          })
          .catch(function () {
            setUploadStatus(false);
            message.error("上传失败");
          });
      };
      if (!chunkList.current.includes(item.filename)) requestList.push(fn);
    });
    uploadSend(startIndex, requestList);
  }

  // 上传单个切片
  async function uploadSend(index, requestList) {
    if (index >= requestList.length) {
      uploadComplete();
      return;
    }
    requestList[index] && (await requestList[index]());
    uploadSend(++index, requestList);
  }

  // 上传完成
  function uploadComplete() {
    setFinishStatus(true);
    axios
      .get(
        `${baseUrl}/upload/finish?hash=${hash.current}&uploadFile=${fileRef.current.name}&uuid=${uuid}&project=${project}`
      )
      .then((data) => {
        setFinishStatus(false);
        if (data.data.code === "1") {
          setDownloadUrl(data.data.data.download_url);
        }
      })
      .catch(() => {
        setFinishStatus(false);
        setUploadStatus(false);
        message.error("上传失败");
      });
  }

  const copyDownloadUrl = () => {
    copy(downloadUrl);
    message.success(`复制链接${downloadUrl}`);
  };

  return (
    <div style={{ margin: "15px 0" }}>
      {uploadStatus ? (
        <>
          {uploadProgress < 100 && (
            <div style={{ paddingRight: "20px" }}>
              <span>{file.name}</span>
              <Progress percent={uploadProgress} status="active" />
            </div>
          )}
          {uploadProgress >= 100 && (
            <div className="complete">
              {finishStatus ? (
                "文件上传完毕，后端转存中，请耐心等待"
              ) : (
                <>
                  <span>
                    <FileZipOutlined />:{" "}
                    <span style={{ color: "#0958d9" }}>{file.name} </span>
                  </span>
                  <Button onClick={copyDownloadUrl} className="buttonUpload">
                    复制链接
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <span>
            <FileZipOutlined />:{" "}
            <span style={{ color: "#000" }}>{file.name} </span>
          </span>
          <Button onClick={reUpload} className="buttonUpload">
            文件重传
          </Button>
        </>
      )}
    </div>
  );
};

export default UploadingPanel;
