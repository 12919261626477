import { useState, useEffect } from "react";
import { Upload, Tooltip } from "antd";
import "./uploadingPanel.css";
import UploadingPanelItem from "./UploadingPanelItem";
import { InboxOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Select } from "antd";
import axios from "axios";
const { Dragger } = Upload;

const UploadingPanel = ({ uuid, baseUrl }) => {
  const [fileList, setFileList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState("");
  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file, FileList) => {
      setFileList(FileList);
      return false;
    },
    showUploadList: false,
  };
  const handleChange = (value) => {
    setProject(value);
  };
  async function getProject() {
    axios
      .get(`${baseUrl}/upload/project?uuid=${uuid}`)
      .then((res) => {
        setProjectList(res.data);
        setProject(res.data[0]?.value);
      })
      .catch(() => {});
  }

  useEffect(() => {
    getProject();
  }, []);

  return (
    <div className="uploading-panel">
      <div style={{ marginBottom: "10px", overflow: "hidden" }}>
        <div style={{ float: "right" }}>
          <Tooltip placement="top" title={"上传过程中不要改动"}>
            <ExclamationCircleTwoTone />
          </Tooltip>{" "}
          上传文件至 ：
          <Select
            value={project}
            style={{ width: 150 }}
            onChange={handleChange}
            options={projectList}
          />
        </div>
      </div>

      <div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或拖拽文件到本区域进行上传</p>
          <p className="ant-upload-hint">支持单个或批量上传</p>
        </Dragger>
      </div>
      <div>
        {fileList.map((i) => {
          return (
            <UploadingPanelItem
              uuid={uuid}
              file={i}
              key={i.name}
              project={project}
              baseUrl={baseUrl}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UploadingPanel;
