import "./App.css";
import { JSEncrypt } from "jsencrypt";
import { useEffect, useState } from "react";
import ParticlesBg from "particles-bg";
import axios from "axios";
import UploadingPanel from "./UploadingPanel";
import { Button, Checkbox, Form, Input, message } from "antd";
const baseUrl = "https://upload.cowarobot.com";
const redirect_uri = "https://upload.cowarobot.com/qrlogin";
const goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a228448b037ed00d&redirect_uri=${redirect_uri}&response_type=code&state=STATE`;
const public_key =
  "-----BEGIN RSA Public Key-----\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqGGJxB55QvtphLLFF/SM\n" +
  "U4cQsAu3jZ3rcYsYI8F2frzHmoLqOTelnkmZPaOjNsRp/N6RXFeeUh3rIo9gGgG2\n" +
  "qJ08W/IIS13gWq+6PRabrzE/Wj7wN3BT/hNWWaSkXy41ESnWS8tt7A/on2kdwW7m\n" +
  "xRIXUqRM2xOBqqJ3BSX1FKL4PM8pLqNLd7iyGEf21Ps3elNNyNge4lPLCf8e3mwR\n" +
  "HsI7XjVL6tr3kHdoXyn/gi6G6rcX43et7eTlcGseSbgJ89l19U2tjkb87IK3yH61\n" +
  "d5wcXCiyA7nGp2LUqW2sD6NRtkrF6BXgV/T+VWXyCIJGZLpScMEcyHMu018t5vYQ\n" +
  "DwIDAQAB\n" +
  "-----END RSA Public Key-----";
const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
};
function App() {
  const [showQR, setShowQR] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [uuid, setUuid] = useState("");
  useEffect(() => {
    const QRLoginObj = window.QRLogin({
      id: "login_QR",
      goto: goto,
      width: "300",
      height: "278",
      style: "width:300px;height:278px;border:none", //可选的，二维码html标签的style属性
    });
    const handleMessage = function (event) {
      var origin = event.origin;
      // 使用 matchOrigin 方法来判断 message 来自页面的url是否合法
      if (QRLoginObj && QRLoginObj.matchOrigin(origin)) {
        var loginTmpCode = event.data;
        // 在授权页面地址上拼接上参数 tmp_code，并跳转
        window.location.href = `${goto}&tmp_code=${loginTmpCode}&state="${redirect_uri}"`;
      }
    };

    if (typeof window.addEventListener != "undefined") {
      window.addEventListener("message", handleMessage, false);
    } else if (typeof window.attachEvent != "undefined") {
      window.attachEvent("onmessage", handleMessage);
    }
    return () => {
      if (typeof window.removeEventListener != "undefined") {
        window.removeEventListener("message", handleMessage, false);
      } else if (typeof window.detachEvent != "undefined") {
        window.detachEvent("onmessage", handleMessage);
      }
    };
  }, []);

  const onFinish = (values) => {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(public_key);
    axios
      .post(`${baseUrl}/login`, {
        username: values.username,
        password: encryptor.encrypt(values.password),
      })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          setUuid(data.data.uuid);
          setShowQR(false);
        }
      })
      .catch((e) => {
        message.error("账号密码错误");
      });
  };

  useEffect(() => {
    const uuid = getCookie("uuid");
    if (uuid) {
      setShowQR(false);
      setUuid(uuid);
    }
  }, []);

  return (
    <div className="App">
      {showQR && (
        <div
          className="loginQR"
          style={!showPassword ? {} : { position: "absolute", left: "-500px" }}
        >
          <div
            id="login_QR"
            style={
              showQR
                ? { display: "block", zIndex: "99" }
                : { display: "none", zIndex: "99" }
            }
          ></div>
          <div
            onClick={() => {
              setShowPassword(true);
            }}
          >
            <Button type="link">账号密码登录</Button>
          </div>
        </div>
      )}
      {showQR && showPassword && (
        <div className="loginIphone">
          <div>
            <Form
              name="basic"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{
                maxWidth: 500,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="账号"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入账号",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="秘密"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div
            onClick={() => {
              setShowPassword(false);
            }}
          >
            <Button type="link">飞书扫码登录</Button>
          </div>
        </div>
      )}
      {!showQR && (
        <div className="content">
          <UploadingPanel uuid={uuid} baseUrl={baseUrl} />
        </div>
      )}
      <ParticlesBg type="square" num={10} bg={true} />
    </div>
  );
}

export default App;
